// Pages
export const FEED_VIEW = 'FEED_VIEW';
export const INBOX_VIEW = 'INBOX_VIEW';
export const MATERIALS_VIEW = 'Material Viewed';
export const NOTIFICATIONS_VIEW = 'NOTIFICATIONS_VIEW';
export const SETTINGS_VIEW = 'SETTINGS_VIEW';
export const INVITE_VIEW = 'INVITE_STUDENTS_VIEW';
export const POST_VIEW = 'POST_VIEW';
export const LOGIN_VIEW = 'LOGIN_VIEW';
export const SUBMISSIONS_VIEW = 'SUBMISSIONS_VIEW';
export const ALL_IMPORTANT_POSTS_VIEW_FROM_SIDEBAR =
  'ALL_IMPORTANT_POSTS_VIEW_FROM_SIDEBAR';
export const IMPORTANT_POST_VIEW_FROM_SIDEBAR =
  'IMPORTANT_POST_VIEW_FROM_SIDEBAR';

// Onboarding
export const CODE_LOGIN = 'CODE_LOGIN';
export const LAUNCH_AULA = 'LAUNCH_AULA';
export const REQUEST_CODE_LOGIN = 'REQUEST_CODE_LOGIN';
export const INTENTIONAL_LOGIN = 'INTENTIONAL_LOGIN';
export const SIGNUP_CREATE = 'SIGNUP_CREATE';
export const SIGNUP_JOIN = 'SIGNUP_JOIN';
export const APP_LAUNCH = 'APP_LAUNCH';

// Interaction
export const POST_EDITOR_START = 'POST_EDITOR_START';
export const COMMENT_EDITOR_START = 'COMMENT_EDITOR_START';
export const INBOX_EDITOR_START = 'INBOX_EDITOR_START';
export const MATERIAL_EDITOR_START = 'MATERIAL_EDITOR_START';
export const MATERIAL_EDITOR_FIRST_EDIT = 'MATERIAL_EDITOR_FIRST_EDIT';
export const CHANGE_CLASSROOM = 'CHANGE_CLASSROOM';
export const SELECT_SPACE_STAFF_SPACE_SELECTOR =
  'SELECT_SPACE_STAFF_SPACE_SELECTOR';
export const SPACE_SELECTOR_CATEGORY_SELECTED =
  'SPACE_SELECTOR_CATEGORY_SELECTED';
export const SPACE_SELECTOR_SUB_CATEGORY_SELECTED =
  'SPACE_SELECTOR_SUB_CATEGORY_SELECTED';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const PROMOTE_PARTICIPANT = 'PROMOTE_PARTICIPANT';
export const UNPROMOTE_PARTICIPANT = 'UNPROMOTE_PARTICIPANT';
export const JOIN_CLASSROOM = 'JOIN_CLASSROOM';
export const CREATE_CLASSROOM = 'CREATE_CLASSROOM';
export const CLICK_TAG = 'CLICK_TAG';
export const USER_CHANGE_AVATAR = 'USER_CHANGE_AVATAR';
export const USER_CHANGE_STATUS = 'USER_CHANGE_STATUS';
export const CONVERSATION_VIEW = 'CONVERSATION_VIEW';
export const OMNISEARCH_OPEN_MODAL = 'OMNISEARCH_OPEN_MODAL';
export const OMNISEARCH_QUERY = 'OMNISEARCH_QUERY';
export const SCREEN_READER_ENABLED = 'SCREEN_READER_ENABLED';
export const LOAD_MORE_COMMENTS = 'LOAD_MORE_COMMENTS';
export const ADD_DISCUSSION = 'ADD_DISCUSSION';
export const REMOVE_DISCUSSION = 'REMOVE_DISCUSSION';
export const HIDE_DISCUSSION = 'HIDE_DISCUSSION';
export const SHOW_DISCUSSION = 'SHOW_DISCUSSION';
export const JUMP_TO_DISCUSSION = 'JUMP_TO_DISCUSSION';
export const SEARCH_INBOX_DM = 'SEARCH_INBOX_DM';

/* Pinned posts */
export const MAKE_IMPORTANT = 'MAKE_IMPORTANT';
export const MAKE_NOT_IMPORTANT = 'MAKE_NOT_IMPORTANT';

export const CLASSROOM_CHANGE_AVATAR = 'CLASSROOM_CHANGE_AVATAR';
export const EMAIL_INVITE = 'EMAIL_INVITE';

// My Assignment
export const FETCH_ASSIGNMENTS = 'FETCH_ASSIGNMENTS';
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const CONVERT_ASSIGNMENT = 'CONVERT_ASSIGNMENT';
export const EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT';
export const CLOSE_ASSIGNMENT = 'CLOSE_ASSIGNMENT';
export const REOPEN_ASSIGNMENT = 'REOPEN_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const EXPORT_ASSIGNMENT_GRADES = 'EXPORT_ASSIGNMENT_GRADES';
export const BULK_DOWNLOAD = 'BULK_DOWNLOAD';

// Engagement
export const CLICK_ENGAGEMENT_STUDENT_TAB = 'CLICK_ENGAGEMENT_STUDENT_TAB';

// Submissions
export const FILTER_SUBMISSIONS_BY_GROUP_ID = 'FILTER_SUBMISSIONS_BY_GROUP_ID';
export const VIEW_STUDENT_SUBMISSION = 'VIEW_STUDENT_SUBMISSION';
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION';

// Groups settings
export const EXPORT_GROUP_SET_PARTICIPANTS = 'EXPORT_GROUP_SET_PARTICIPANTS';
export const CREATE_GROUPS_VIA_CSV_UPLOAD = 'CREATE_GROUPS_VIA_CSV_UPLOAD';

// NOTIFICATIONS
export const CLICK_LOCAL_NOTIFICATION = 'CLICK_LOCAL_NOTIFICATION';
export const CLICK_PUSH_NOTIFICATION = 'CLICK_PUSH_NOTIFICATION';

// SPACE SELECTOR
export const SPACE_SELECTOR_DISMISSED = 'SPACE_SELECTOR_DISMISSED';
export const SPACE_SELECTOR_SPACE_SELECTED = 'SPACE_SELECTOR_SPACE_SELECTED';
export const SPACE_SELECTOR_ARCHIVED_TOGGLE = 'SPACE_SELECTOR_ARCHIVED_TOGGLE';
export const SPACE_SELECTOR_SEARCH = 'SPACE_SELECTOR_SEARCH';
export const SPACE_SELECTOR_SORT = 'SPACE_SELECTOR_SORT';
export const SPACE_SELECTOR_SPACE_STARRED = 'SPACE_SELECTOR_SPACE_STARRED';
export const SPACE_SELECTOR_SPACE_UNSTARRED = 'SPACE_SELECTOR_SPACE_UNSTARRED';

// ITEM TYPES
export const SPACE_SELECTOR_ITEM_TYPE = 'spaceSelector';
export const ASSIGNMENT_ITEM_TYPE = 'Assignment';
export const MATERIAL_ITEM_TYPE = 'Material';
export const INBOX_MESSAGE_ITEM_TYPE = 'UBInboxMessage';
export const COMMENT_ITEM_TYPE = 'UBComment';

// VISIBLE LEARNING
export const VIEW_PROGRESS_OVERVIEW = 'VIEW_PROGRESS_OVERVIEW';

// LTI
export const LTI_BLOCK_LAUNCH = 'LTI_BLOCK_LAUNCH';
