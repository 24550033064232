const routing = (state) => state.routing;

export const location = (state) => routing(state).locationBeforeTransitions;

export const getCurrentPath = (state) => location(state).pathname;

export const currentPathMatches = (state, regex) =>
  new RegExp(regex).test(getCurrentPath(state));

export const settingsTab = (state) => {
  const { pathname } = location(state);
  const parts = pathname.split('/').filter((part) => !!part);
  return parts.length > 3 ? parts[3] : 'participants';
};

export const spaceBarTab = (state) => {
  const { pathname } = location(state);
  const parts = pathname.split('/').filter((part) => !!part);
  const isJourney =
    parts.includes('assignments') ||
    parts.includes('educatorArea') ||
    parts.includes('engagement') ||
    parts.includes('item-bank') ||
    parts.includes('materials') ||
    parts.includes('reports') ||
    parts.includes('submissions');

  const isPeople = parts.includes('groups') || parts.includes('participants');

  const isPeopleOrCommunity = isPeople ? 'people' : 'community';
  return isJourney ? 'journey' : isPeopleOrCommunity;
};

export const peopleTab = (state) => {
  const { pathname } = location(state);
  const parts = pathname.split('/').filter((part) => !!part);
  return parts.includes('groups') ? 'groups' : 'participants';
};

export const journeyTab = (state) => {
  const { pathname } = location(state);
  const parts = pathname.split('/').filter((part) => !!part);

  const isInMaterials = parts.includes('materials');
  if (isInMaterials) {
    return 'materials';
  }

  const isInSubmission = parts.includes('submissions');
  if (isInSubmission) {
    return 'submissions';
  }

  const isInReport = parts.includes('reports');
  if (isInReport) {
    return 'reports';
  }

  const isInItemBank = parts.includes('item-bank');
  if (isInItemBank) {
    return 'itemBank';
  }

  const isInAssignment = parts.includes('assignments');
  if (isInAssignment) {
    return 'assignments';
  }

  const isInEducatorArea = parts.includes('educatorArea');
  if (isInEducatorArea) {
    return 'educatorArea';
  }

  const isEngagementInsights = parts.includes('engagement');
  if (isEngagementInsights) {
    return 'engagement';
  }

  return 'materials';
};

export const isNotificationsOpen = (state) => {
  const {
    query: { notifications: currentlyDisplayed },
  } = location(state);
  return !!currentlyDisplayed;
};

export const isSettingsTabOpen = (state) => {
  const { pathname } = location(state);
  return pathname.includes('settings');
};
