import { getStore } from '../store';

const getBaseUrl = (service) => {
  if (service) {
    const serviceEndpoint = process.env[`BASE_${service}`];
    if (serviceEndpoint) return serviceEndpoint;
  }
  return getStore().getState().institution.keys.endpoints.api;
};
const getBaseUrlV2 = (service) => {
  if (service) {
    const serviceEndpoint = process.env[`BASE_${service}`];
    if (serviceEndpoint) return serviceEndpoint;
  }
  return getStore().getState().institution.keys.endpoints.apiV2;
};

export const FILE_UPLOAD = () => `${getBaseUrlV2('WEB_SCAPER')}/file/upload`;
export const WEB_SCAPER = () => `${getBaseUrlV2('WEB_SCAPER')}/meta/scrap`;
export const AULA_WEB_SCAPER = () =>
  `${getBaseUrlV2('AULA_WEB_SCAPER')}/meta/scrap/aula`;
export const LTI_CONSUMER = () =>
  `${getBaseUrlV2('LTI_CONSUMER')}/lti-consumer`;
export const ASSIGNMENTS = () => `${getBaseUrl('ASSIGNMENTS')}/assignments`;
export const OEMBED_PROXY = () =>
  `${getBaseUrlV2('OEMBED_PROXY')}/meta/scrap/oembed`;

export const GENERAL =
  process.env.WEB_AUTH_URL || 'https://general.aula.education';

export default {
  FILE_UPLOAD,
  WEB_SCAPER,
  ASSIGNMENTS,
  LTI_CONSUMER,
  OEMBED_PROXY,
};
